<template>
    <div>

        <div class="card-toolbar mb-5 d-flex justify-content-between">
          <div>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
              <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
          </div>
          <div>
            <router-link v-if="$can('supplier_checking.supplier_checking_history')" to="/purchases/supplier-checking-history" class="btn btn-primary font-weight-bolder h-100">
              {{ $t('MENU.supplier_checking_history') }}
            </router-link>
          </div>

        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row mb-5">

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('supplier_checking.supplier')}}</label>
                            <multiselect v-model="supplier"
                                         :placeholder="$t('supplier_checking.supplier')"
                                         label="name"
                                         track-by="id"
                                         :options="suppliers"
                                         :multiple="false"
                                         :taggable="false"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false"
                                         @search-change="getSuppliers($event)">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mb-5">
                            <label for="from_date">{{$t('supplier_checking.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mb-5">
                            <label for="to_date">{{$t('supplier_checking.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>




                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="actual_amount" slot-scope="props">
                        <span>
                            {{props.row.actual_amount}}
                        </span>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <template v-if="props.row.last_validated_date || !props.row.supplier_validation_id">

                            <v-btn icon color="pink" v-if="$can('supplier_checking.update')"  @click="goToEdit(props.row.supplier_id,props.row.currency_id)">
                                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
<!--                           -->
                            <v-btn icon color="pink"  v-if="$can('supplier_checking.update')" :to="'/purchases/supplier-checking/edit/'+props.row.supplier_validation_id">
                                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <template v-if="$can('supplier_checking.approve') && !props.row.is_approved">
                            <b-dropdown v-if="$can('supplier_checking.approve') && props.row.supplier_validation_id && !props.row.last_validated_date" :id="'dropdown-offset_'+props.row.supplier_validation_id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
                                <a class="dropdown-item" v-if="!props.row.last_validated_date" href="javascript:;" @click="makeApprove(props.row.supplier_validation_id)">
                                    {{ $t('approve') }}
                                </a>
                            </b-dropdown>

                        </template>

                    </template>
                </v-server-table>

            </div>
        </div>
        <!--end::customer-->

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-supplier-checking",
        data() {
            return {
                mainRoute: 'purchases/supplier-checking',
                subMainRoute: 'purchases/supplier-check',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    supplier_id: this.$route.query.supplier_id ? this.$route.query.supplier_id : null,
                    from_date: null,
                    to_date: null,
                },
                // status_list: [],
                columns: ['supplier_name', 'credit','debit', 'currency_name', 'last_validated_date',  'actual_amount', 'actions'],

                data: [],
                supplier: null,
                suppliers: [],


            }
        },
        watch: {
            supplier: function (val) {
                if (val) {
                    this.filters.supplier_id = val.id;
                } else {
                    this.filters.supplier_id = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        supplier_name: that.$t('supplier_checking.supplier'),
                        credit: that.$t('supplier_checking.credit'),
                        debit: that.$t('supplier_checking.debit'),
                        currency_name: that.$t('supplier_checking.currency_name'),
                        actual_amount: that.$t('supplier_checking.actual_amount'),
                      last_validated_date: that.$t('supplier_checking_history.last_validate_date'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.supplier_checking")}]);
        },
        methods: {
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.supplier_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.supplier = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            async getSuppliers(filter) {
                if(filter && filter.length >= 3)
                    await ApiService.get(this.mainRouteDependency + "/suppliers", {params:{filter: filter}}).then((response) => {
                        this.suppliers = response.data.data;
                    });
            },

            goToEdit(supplier_id, currency_id) {
                this.$router.push({
                    name: 'supplier-checking.edit', query: {
                        supplier_id: supplier_id,
                        currency_id: currency_id,
                    }
                });
            },
            makeApprove(supplier_validation_id) {
                this.$confirmAlert(this.$t('do_you_want_make_it_approved'), this.actionApprove, supplier_validation_id);
            },

            actionApprove(supplier_validation_id) {
                ApiService.patch(`${this.subMainRoute}/approved/${supplier_validation_id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },

        },
    };
</script>
